import favicon16x16Light from "@assets/favicons/favicon-16x16-light.png";
import favicon16x16 from "@assets/favicons/favicon-16x16.png";
import favicon32x32Light from "@assets/favicons/favicon-32x32-light.png";
import favicon32x32 from "@assets/favicons/favicon-32x32.png";
import favicon64x64Light from "@assets/favicons/favicon-64x64-light.png";
import favicon64x64 from "@assets/favicons/favicon-64x64.png";
import favicon96x96Light from "@assets/favicons/favicon-96x96-light.png";
import favicon96x96 from "@assets/favicons/favicon-96x96.png";

declare let COMMITHASH: string;
declare let LASTCOMMITDATETIME: string;

function convertRemToPixels(rem: number): number {
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function getCSSProperty(propName: string, element: Element | null = null): string {
	if (element == null) {
		return getComputedStyle(document.documentElement).getPropertyValue(propName).trim();
	} else {
		return getComputedStyle(element).getPropertyValue(propName).trim();
	}
}

function getCSSPropertyAsPixels(propName: string, element: Element | null = null): number {
	const property = getCSSProperty(propName, element);
	if (property.includes("rem")) {
		return convertRemToPixels(parseFloat(property.replace("rem", "")));
	} else if (property.includes("px")) {
		return parseFloat(property.replace("px", ""));
	} else if (property.includes("vw")) {
		return getViewportWidth() * (parseFloat(property.replace("vw", "")) / 100);
	} else if (property.includes("vh")) {
		return getViewportHeight() * (parseFloat(property.replace("vh", "")) / 100);
	} else {
		throw "Invalid property unit!";
	}
}

export function getCornerRadius(): number {
	return getCSSPropertyAsPixels("--thumbnail-border-radius");
}

export function getMaxShadowWidth(): number {
	return getCSSPropertyAsPixels("--thumbnail-max-shadow-width");
}

export function getViewportWidth(): number {
	// Ignores the scrollbar if one is visible.
	return document.documentElement.clientWidth;
}

export function getViewportHeight(): number {
	// Ignores the scrollbar if one is visible.
	return document.documentElement.clientHeight;
}

export function secondsFromMicroSeconds(us: number): number {
	return Math.trunc(us / 1e6);
}

export function microSecondsFromSeconds(s: number): number {
	return s * 1e6;
}

export function secondsToDuration(secs: number): string {
	const hours = Math.floor(secs / 3600);
	const minutes = Math.floor((secs % 3600) / 60);
	const seconds = secs % 60;

	let result = "";
	if (hours > 0) {
		result += hours + ":";
	}

	if (minutes < 10 && hours > 0) {
		result += "0";
	}
	result += minutes + ":";

	if (seconds < 10) {
		result += "0";
	}
	result += seconds;
	return result;
}

export interface Named {
	name: string;
}

export function sortByName(list: Named[]): void {
	list.sort((a, b) => {
		return a.name.localeCompare(b.name);
	});
}

export function createHeader<T extends Node>(header: T, back: () => void): HTMLDivElement {
	const outer = document.createElement("div");
	outer.setAttribute("class", "sub-page-header");

	const button = document.createElement("button");
	button.setAttribute("class", "btn btn-primary me-2 fas fa-arrow-left back-button");
	button.addEventListener("click", back);

	outer.appendChild(button);
	outer.appendChild(header);
	return outer;
}

const URL_REGEX = new RegExp(
	/(((https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??[-+=&;%@.\w_]*#?[.!/\\\w]*)?)/,
);
export function splitQuery(query: string): string[] | string {
	const sub = query.split(" ");
	if (sub.every(value => value.match(URL_REGEX))) {
		return sub;
	} else {
		return query;
	}
}

export function always<T>(p: Promise<T>, f: () => void): Promise<T> {
	return p.then(
		v => {
			f();
			return v;
		},
		v => {
			f();
			return Promise.reject(v);
		},
	);
}

export function disablingPromise<T>(button: HTMLElement, promise: Promise<T>): Promise<T> {
	button.setAttribute("disabled", "");
	return always(promise, () => button.removeAttribute("disabled"));
}

export function spinningPromise<T>(spinner: HTMLElement, promise: Promise<T>): Promise<T> {
	spinner.classList.remove("d-none");
	return always(promise, () => spinner.classList.add("d-none"));
}

export function filterMap<T, U>(input: T[], f: (value: T) => U | undefined): U[] {
	const result = new Array(input.length) as U[];
	let offset = 0;
	input.forEach(value => {
		const v = f(value);
		if (v !== undefined) {
			result[offset] = v;
			offset += 1;
		}
	});
	result.length = offset;
	return result;
}

export class RacingTask {
	private current = -1;

	run<T>(promise: Promise<T>): Promise<T> {
		const id = Math.random();
		this.current = id;
		return promise.then(v => (this.current == id ? Promise.resolve(v) : Promise.reject()));
	}

	cancel(): void {
		this.current = -1;
	}
}

const themeUris = {
	dark: [favicon96x96, favicon64x64, favicon32x32, favicon16x16],
	light: [favicon96x96Light, favicon64x64Light, favicon32x32Light, favicon16x16Light],
};

function update(darkTheme: boolean) {
	const uris = darkTheme ? themeUris.light : themeUris.dark;
	// Remove potentially existing old favicons.
	document.querySelectorAll("[data-favicon]").forEach(e => e.remove());

	uris.forEach(uri => {
		const target = document.querySelector("head") as Element;
		const link = document.createElement("link");
		link.setAttribute("rel", "icon");
		link.setAttribute("type", "image/png");
		const sizes = uri.match(/favicon-(\d+x\d+)/);
		if (sizes != null) {
			link.setAttribute("sizes", sizes[1]);
		}
		link.setAttribute("href", uri);
		link.setAttribute("data-favicon", "");
		target.appendChild(link);
	});
}

export function setUpFavicons(): void {
	const query = window.matchMedia("(prefers-color-scheme: dark)");
	query.addEventListener("change", event => update(event.matches));
	update(query.matches);
}

export function getReadableCommitTime(): string {
	return new Date(Date.parse(LASTCOMMITDATETIME)).toLocaleString(undefined, {
		year: "numeric",
		month: "short",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
	});
}

export function getReadableCommitHash(): string {
	return COMMITHASH.substring(0, 10);
}

export function printWelcomeMessage(): void {
	const leierkastenBanner: string = `
		    __    ____________________  __ __ ___   __________________   __
		   / /   / ____/  _/ ____/ __ \\/ //_//   | / ___/_  __/ ____/ | / /
		  / /   / __/  / // __/ / /_/ / ,<  / /| | \\__ \\ / / / __/ /  |/ /
		 / /___/ /____/ // /___/ _, _/ /| |/ ___ |___/ // / / /___/ /|  /
		/_____/_____/___/_____/_/ |_/_/ |_/_/  |_/____//_/ /_____/_/ |_/

	`;
	console.log(leierkastenBanner.replace("\n", "").replace(/\t/g, ""));
	console.log("Welcome to Leierkasten!");
	console.log(`You are running commit ${getReadableCommitHash()} built at ${getReadableCommitTime()}`);
}
